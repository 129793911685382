@import "../../styles/varriables.scss";

.admin-sidebar {
  .item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    .list-item {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px;
        border-radius: 5px;

      .icon {
        color: $light-color;
      }

      .admin-list-item-title {
        font-size: 14px;
        color: $light-color;

      }

      &:hover{
        background-color: $soft-blue-bg;
      }
    }

    .title {
      font-size: 12px;
      font-weight: bold;
      color: $electric-green-color;
    }
  }
}
