/* Background */
$dark-blue-bg: #000066; 
$soft-blue-bg: #0066CC;
$electric-green-bg: #66CC33;
$orange-bg: #FF9933;
$light-bg: #ffffff;
$grey-border: #C5C5C5;

/* text color */
$electric-green-color: #66CC33;
$soft-blue-color: #0066CC;
$dark-blue-color:#000066;
$orange-color: #FF9933;
$light-color: #ffffff;
$grey-border: #C5C5C5;
/* font */
$font-family: "Rubik";