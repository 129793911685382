@import "../../styles/varriables.scss";

.updates {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-top: 1px solid $light-color;
  .title{
    margin-top:10px;
  }
  .updates-setting {
    display: flex;
    gap: 10px;
    select {
      padding: 10px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: white;
      color: #333;
      cursor: pointer;
      outline: none;
      width: 200px;

      &:focus {
        color: #555;
      }

      &::-ms-expand {
        display: none;
      }

      &:hover {
        border-color: #aaa;
      }

      option {
        background-color: white;
        color: #333;

        &:checked {
          background-color: #f0f0f0;
        }
      }
    }

    button {
      padding: 5px;
      cursor: pointer;
      background-color: $orange-color;
      border: 1px solid transparent;
      border-radius: 5px;
      font-weight: bold;
      color: $dark-blue-color;
      font-size: 14px;
    }
  }
}
