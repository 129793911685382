@import "../../styles//varriables.scss";

.admin-add {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.724);
  display: flex;
  align-items: center;
  justify-content: center;

  .add-modal {
    padding: 50px;
    border-radius: 5px;
    background-color: $soft-blue-bg;
    position: relative;

    h1 {
      margin-bottom: 40px;
      font-size: 24px;
      color: $orange-color;
    }

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      font-weight: bold;
    }

    form {
      display: flex;
      flex-wrap: wrap;
      max-width: 500px;
      justify-content: space-between;
      align-items: last baseline;
    }

    .item {
      width: 40%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;

      label {
        font-size: 14px;
        font-weight: bold;
      }

      .text-input {
        padding: 10px;
        background-color: transparent;
        color: white;
        outline: none;
        border: 1px solid $electric-green-color;
        border-radius: 5px;
      }

      .select-input {
        padding: 10px;
        border: 1px solid $electric-green-color;
        font-size: 16px;
        border-radius: 5px;
        background-color: white;
        color: #333;
        cursor: pointer;
        outline: none;
        width: 200px;

        &:focus {
          color: #555;
        }

        &::-ms-expand {
          display: none;
        }

        &:hover {
          border-color: #aaa;
        }

        option {
          background-color: white;
          color: #333;

          &:checked {
            background-color: #f0f0f0;
          }
        }
      }
      .checkbox-container {
        
        max-height: 300px; /* Ajusta la altura máxima según tus preferencias */
        overflow-y: auto;
        border: 1px solid #ccc;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .checkbox-option{
          display: flex;
          gap: 10px;
          align-items: baseline;

          .checkbox{
            width: auto;
          
          }

          .label{
          }
        }
      }
    }
    button {
      width: 100%;
      padding: 10px;
      cursor: pointer;
      background-color: $orange-color;
      color: $light-color;
      border: 1px solid;
      border-color: transparent;
      border-radius: 5px;
      font-weight: bold;
    }
  }
}
