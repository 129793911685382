@import '../../styles//varriables.scss';

.connections {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    select{
        background-color: transparent;
        border: none;
        color: $electric-green-color;
        font-size: 20px !important;
    }
    button{
        padding: 5px;
        cursor: pointer;
        background-color: $orange-color;
        border: 1px solid transparent;
        border-radius: 5px;
        font-weight: bold;
        color: $dark-blue-color;
        font-size: 14px;
        }
  }
  .list-container {
    .list {
      background-color: green;
      margin: 0;
      padding: 0;
      .item {
        display: flex;
        justify-content: space-between;
        list-style: none;
        padding: 10px 20px;
        border: 1px solid black;

      .itemActions{
        display: flex;
        gap: 10px;
        cursor: pointer;
      }
      }
    }
  }
}
