@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Flex Properties */

.flex-center {
  @apply flex justify-center items-center;
}

.flex-start {
  @apply flex justify-start items-start;
}

.flex-end {
  @apply flex justify-end items-center;
}

.flex-between {
  @apply flex justify-between items-center;
}

.flex-around {
  @apply flex justify-around items-center;
}

.flex-evenly {
  @apply flex justify-evenly items-center;
}

/* LIST PROPERTIES */
.custom-li {
  @apply p-4 list-none  border-b-[1px] border-b-[#EBEBEB] cursor-pointer;
}

/* SLICK SLIDER */
.slick-slide > div{
   @apply flex-center 
    /* cualquier otro estilo que necesites */
}

/* .slick-track{
  @apply !w-full m-0 flex justify-center
} */

/* .slider-container{
  background-color: #f8f8f8 !important;
}

.slick-slider .slick-track, .slick-slider .slick-list {
    @apply flex-between
} */

.slick-initialized .slick-slide {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px !important;
}



/* modal */
.modal-overlay {
  @apply fixed top-0 left-0 w-screen h-screen flex-center bg-black/50 z-[1];
}

.modal {
  @apply absolute top-[0%] xs:w-[90%] md:w-[35%] xs:max-h-[96vh]  p-5 !z-[2] flex-center flex-col rounded-[2rem] bg-white;
}

.modal-header {
  @apply font-bold pb-[10px];
}

.modal-inputs-container {
  @apply w-full flex flex-col gap-3 overflow-auto;
}

.modal-close-button {
  @apply absolute right-3 top-1 p-1 text-[#0079bf] hover:border hover:rounded-full border-transparent hover:bg-[#F8F8F8];
}

/* COLORS */
.bg-electric-green{
@apply bg-[#66CC33]
}

.text-kontevo-green{
@apply text-[#66CC33]
}

.bg-kontevo-orange{
@apply bg-[#FF9933]
}

.text-kontevo-orange{
@apply text-[#FF9933]
}

.bg-kontevo-darkblue{
@apply bg-[#000066]
}

.text-kontevo-darkblue{
@apply text-[#000066]
}

.bg-kontevo-lightblue{
@apply bg-[#0066CC]
}

.text-kontevo-lightblue{
@apply text-[#0066CC]
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Safari y Chrome */
}