@import "../../../styles/varriables.scss";

.dateFilterContainer {
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  gap: 10px;
  .filterTitle {
    h5 {
      font-size: 14px;
      color: $light-color;
      text-transform: capitalize;
      font-weight: bold;
    }
  }
  .dateWrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .dateOption{
    display: flex;
    align-items: baseline;
    gap: 1rem;
    label{
        min-width: 2rem;
        color: $light-color;
        font-size: 13px;
    }
    input{
        padding: 0.375rem 1rem;
        margin-bottom: 0;
        border: 1px solid $electric-green-color;
        border-radius: 12px;
    }
  }
}
