@import "../../styles//varriables.scss";

.single {
  width: 100%;
  display: flex;
  justify-content: center;
  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .top-info {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;

      h1 {
        color: $electric-green-color;
      }

      button {
        padding: 5px;
        cursor: pointer;
        background-color: $orange-color;
        border: 1px solid transparent;
        border-radius: 5px;
        font-weight: bold;
        color: $dark-blue-color;
        font-size: 14px;
      }

      h1 {
        font-weight: 500;
      }
    }
    .details {
      width: 100%;
      display: flex;
      align-items: baseline;
      justify-content: space-evenly;
      flex-wrap: wrap;
      gap: 20px;
      font-size: 18px;
      .item {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 30px 0px;

        .item-title {
          font-weight: bold;
          margin-right: 10px;
          text-transform: capitalize;
        }
        .text-input,
        .select-input,
        .checkbox-container {
          padding: 10px;
          background-color: $light-bg;
          color: $dark-blue-color;
          outline: none;
          border: 1px solid $electric-green-color;
          border-radius: 5px;
        }
        .select-input{
          min-height: 300px;
          padding: 0;
          option{
            padding: 10px;
            border-bottom: 1px solid $electric-green-color ;
          }
        }

        .checkbox-container {
          max-height: 300px; /* Ajusta la altura máxima según tus preferencias */        min-width: 200px;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .checkbox-option {
            display: flex;
            gap: 10px;
            align-items: baseline;

            .checkbox {
              width: auto;
            }

            .label {
              
            }
          }
        }
      }
    }

    .big-details {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;

      .big-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .refresh {
          display: flex;
          gap: 10px;
          .bigitem-title {
            font-weight: bold;
            margin-bottom: 10px;
            text-transform: capitalize;
          }

          .icon {
            background-color: transparent;
            color: $orange-color;
            border: none;
          }

          .icon:hover {
            color: $electric-green-color;
          }
        }
      }
    }
  }
}
