

.text-light-blue {
  color: #0066cc;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.5px;
}

.form-alert {
  font-size: 12px;
  color: #ee2f35;
  font-weight: bold;
}

.signup-input {
  border-width: 1px;
  border-color: #8999af;
  box-shadow: none;
}

.email-input {
  margin-bottom: 0 !important;
}

.email-input-container{
  margin-bottom: 1rem;
}

#signup-left {
  padding: 12rem 10rem;
  background: linear-gradient(
    to top left,
    rgba(255, 255, 255, 0.5),
    rgba(0, 102, 204, 0.5)
  );
}

#signup-right {
  padding: 3rem;
}

#signin-link {
  margin-top: 2rem;
  margin-left: 0.3rem;
}

#signin-link:hover {
  color: #0066cc !important;
}

#signup-form {
  padding: 8em;
}

#form-signup {
  width: 40%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


/* Reglas para pantallas de laptop */
@media (max-width: 1366px) {
  #signup-left {
    padding: 8rem 6rem;
  }
}

/* Reglas para dispositivos más pequeños */
@media (max-width: 768px) {
  #signup-left {
    padding: 4rem 5rem;
  }
  #signup-form {
    padding: 1em;
  }
}
