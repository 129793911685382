@import '../../../styles/varriables.scss';

.admin-ig-accounts{
display: flex;
flex-direction: column;
gap: 20px;

    .info{
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;

        button{
        padding: 5px;
        cursor: pointer;
        background-color: $orange-color;
        border: 1px solid transparent;
        border-radius: 5px;
        font-weight: bold;
        color: $dark-blue-color;
        font-size: 14px;
        }
    }
    .loading{
        color: $light-color;
    }

}