@import "./varriables.scss";

*{
    margin: 0;
    padding: 0;
}

a{
    text-decoration: none;
    color:inherit;
}

.admin-main{
    background-color: $dark-blue-bg;
    color: $light-color;
}

.admin-container{
    display: flex;
}

.admin-sidebar-container{
    width: 250px;
    padding: 5px 20px;
    border-right: 2px solid $light-bg;
}

.admin-content-container{
    padding: 5px 20px;
    width: 100%;
}