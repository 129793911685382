.login {
  height: calc(100vh - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: 60%;
  height: 75%;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.42);
  -webkit-box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.42);
  -moz-box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.42);
  display: flex;
  align-items: center;
  border-radius: 20px;
}

.left,
.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.or {
  border: 2px solid lightgray;
  border-radius: 50%;
  padding: 10px;
  color: gray;
  background-color: white;
  font-weight: bold;
}

.line {
  width: 0.5px;
  height: 70%;
  background-color: lightgray;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}

.loginButton {
  width: 150px;
  padding: 15px 25px;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
}

.google {
  background-color: #df4930;
}

.facebook {
  background-color: #507cc0;
}

.github {
  background-color: #000;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

input {
  width: 200px;
  padding: 15px 20px;
  margin-bottom: 20px;
}

.submit {
  width: 200px;
  background-color: purple;
  color: white;
  font-weight: bold;
  border: none;
  padding: 15px 20px;
  border-radius: 5px;
}

.loginTitle {
  position: absolute;
  color: lightgray;
  top: 150px;
  font-size: 30px;
}

.register-link {
  margin-top: 10px;
  text-decoration: none;
}

.facebook-login-btn {
  padding: 10px 20px;
  background-color: #3b5998;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.facebook-login-btn:hover {
  background-color: #203f7c;
}

.full-height {
  height: 100vh;
}

.login-form-col {
  display: flex;
  flex-direction: column;
  padding: 3em;
 background: linear-gradient(to top left, rgba(255, 255, 255, 0.5), rgba(0, 102, 204, 0.5));


}

.login-col.login-image {
  background-image: url('../../assets/kontevo-logo.png');
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
}

.isologo {
  width: 4em;
  height: auto;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.isologo-row {
  margin-bottom: 5em;
}

.form-input {
  font-size: 14px;
  line-height: 1em;
  letter-spacing: -0.4px;
  padding-top: 1.25em;
  padding-bottom: 1.25em;
  border-width: 1px;
  border-color: transparent;
  border-radius: 0.25rem;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 16px 20px 0 rgba(0, 14, 25, 0.04);
}

.title {
  text-shadow: 2px 2px 4px rgba(227, 162, 162, 0.2);
}

.form-button {
  background-color: #66CC33;
  width: 100%;
  border-width: 1px;
  border-color: transparent;
  border-radius: 0.25rem;
  padding-top: 1.25em;
  padding-bottom: 1.25em;
  font-weight: bold;
  line-height: 1em;
}

.form-button:hover{
  background-color: #FF9933;
  border-color: transparent;
}


.separator {
  width: 100%;
  height: 1px;
  background-color: #000066;
  margin: 20px 0;
}

.register-link{
    color: #0066CC;
    font-weight: bold;
    cursor: pointer;
}

.register-link:hover{
    color: #FF9933 !important;
}

