.dataTable {
  .dataGrid {
    background-color: #fff;
    padding: 20px;

    .MuiDataGrid-toolbarContainer {
      flex-direction: row-reverse;
    }
  }

  .action{
    display: flex;
    gap:15px;
    cursor:pointer;

  .edit{
    color: green;
  }
  
  .delete{
    color: red;
  }

  }

}
